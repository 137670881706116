import React, { useState, useMemo, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { get } from "lodash";
import { Button } from "antd";
import PropTypes from "prop-types";

import { ContentBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import { makeUrl, removeEmptyKeys, removePreloader } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import { showMore } from "../../constants";
import EmptyData from "../../components/EmptyData";
import { withPageDataSlugParams } from "../../containers/withPageData";
import { TV_OBRAZ } from "../../queries/queries.graphql";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query tvObrazDataQuery($slug: String!) {
		hasura: hasura {
			...TV_obraz
		}
	}
`;

function TVObrazPage({
	data,
	pageContext,
	obraz: { refetch },
}) {
	const { theme } = useThemeContext();

	const hasuraData = data.hasura;
	const mediaVideos = get(hasuraData, "media_video_categories[0].media_video_categories_media_videos", []);

	const [pageDate] = useState(get(data, "hasura.media_video_categories[0]", {}));
	const [tvVideos, setTVVideos] = useState(mediaVideos);

	const [offset, setOffset] = useState(9);
	const [isHiddenLoadMore, setIsHiddenLoadMore] = useState(!tvVideos.length);
	const [loading, setLoading] = useState(false);

	async function loadMore() {
		setLoading(true);
		const { data } = await refetch({ slug: pageContext.slug,
			offset });
		setTVVideos([...tvVideos, ...get(data, "media_video_categories[0].media_video_categories_media_videos", [])]);
		setOffset(offset + 9);
		setLoading(false);
		if (!get(data, "media_video_categories[0].media_video_categories_media_videos", []).length) {
			setIsHiddenLoadMore(true);
		}
	}

	const TV_VIDEOS = useMemo(
		() =>
			tvVideos.map((video, idx) => {
				return <VideoElement {...video} key={`medi_video-${idx}`} />;
			}),
		[tvVideos],
	);

	const breadcrumbsData = {
		item: {
			title_full: "медиатека",
			slug: "media",
		},
		item2: {
			title_full: "ТВ-образ",
			slug: "",
		},
	};

	const url = makeUrl.tv_obraz({ pageDate });
	const imageSrc = get(pageDate, "main_image.src", "");

	const { title_full, content_blocks } = pageDate;

	useEffect(() => {
		removePreloader();
	}, []);

	return pageDate ? (
		<Pages entity={pageDate} url={url} >
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={title_full} pageContext={breadcrumbsData} />
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"} mount={content_blocks} className={"text-page"}>
				<div className={"container"}>
					<div className={"text-page_title"}>
						<h1
							itemProp="headline"
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h1,
								color: theme.color.text,
							})}
						>
							{title_full}
						</h1>
					</div>
					<Contents items={content_blocks} />
				</div>
			</ContentBlock>
			<ContentBlock key={"TV"}>
				<div className={"container"}>
					<div className={"row"}>{TV_VIDEOS}</div>
				</div>
			</ContentBlock>
			<ContentBlock key={"LoadMore"} mount={!isHiddenLoadMore}>
				<div className={"container"}>
					<div className="row justify-content-center">
						<Button
							type={"primary"}
							size={"large"}
							className={"mb-4"}
							onClick={loadMore}
							disabled={loading}
							loading={loading}
							style={{
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							}}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleFull={title_full} imageSrc={imageSrc} />
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

function VideoElement({ media_video }) {
	const { theme } = useThemeContext();

	return (
		<div className={"col-sx-12 col-md-4 p-4"}>
			<Link to={makeUrl.video(media_video)} className={" align-items-center"}>
				<img
					src={get(media_video, "main_image_preview.src", get(media_video, "main_image.src", ""))}
					width="100%"
					height="200"
					style={{
						objectFit: "cover",
						...theme.image,
					}}
				/>
				<p
					align={"center"}
					style={{
						fontFamily: theme.fontFamily,
						lineHeight: theme.lineHeight.text,
						fontSize: theme.fontSize.text,
						color: theme.color.text,
					}}
					className={"pt-3"}
				>
					{get(media_video, "title_short", "")}
				</p>
			</Link>
		</div>
	);
}

VideoElement.propTypes = {
	media_video: PropTypes.object,
};

export default withPageDataSlugParams(TVObrazPage, {
	body: TV_OBRAZ,
	name: "obraz",
	variables: { offset: 0 },
});

TVObrazPage.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
	obraz: PropTypes.object,
};

TVObrazPage.defaultProps = {
	data: {},
	pageContext: {},
	location: {},
};
